import {tryGetObjectProperty} from '@/helpers/object';

export const getThumbnailSrcForScene = (scene, size = '') => {
  const customThumbnail = scene.thumbnail || scene.shopify_image_url || scene.self_hosted_thumbnail_url || scene?.video.shopify_image_url;

  if (customThumbnail && customThumbnail.length > 0) {
    if (customThumbnail.includes('reeltok-uploads.s3.us-west-1.amazonaws.com')) {
      return customThumbnail.replace('reeltok-uploads.s3.us-west-1.amazonaws.com', 'd3l6cvllw26ini.cloudfront.net');
    }

    return customThumbnail;
  }

  const thumbnailUrl = scene.video?.thumbnail_url || null;

  if (thumbnailUrl && thumbnailUrl.length > 0) {
    return thumbnailUrl.replace('h_60,w_90', size);
  }

  return null;
};

export const getVideoSrcForScene = scene => {
  const instagramSrc = scene.video.instagram_src;

  if (instagramSrc && instagramSrc.length > 0) {
    return instagramSrc;
  }

  const selfHostedVideo = scene.video.self_hosted_video;
  if (selfHostedVideo) {
    const shopifySources = tryGetObjectProperty(selfHostedVideo, 'shopify_sources');

    if (shopifySources && shopifySources.length > 0) {
      return shopifySources[0].url;
    }

    let src = tryGetObjectProperty(selfHostedVideo, 'src');

    if (src) {
      return src.replace('reeltok-uploads.s3.us-west-1.amazonaws.com', 'd3l6cvllw26ini.cloudfront.net');
    }
  }

  const shopifyVideo = scene.video.shopify_video;

  if (!shopifyVideo) {
    return null;
  }

  return shopifyVideo.sources[0].url;
};
